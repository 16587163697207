// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-addons-jsx": () => import("./../src/pages/addons.jsx" /* webpackChunkName: "component---src-pages-addons-jsx" */),
  "component---src-pages-changelog-jsx": () => import("./../src/pages/changelog.jsx" /* webpackChunkName: "component---src-pages-changelog-jsx" */),
  "component---src-pages-figma-plugin-jsx": () => import("./../src/pages/figma-plugin.jsx" /* webpackChunkName: "component---src-pages-figma-plugin-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-support-jsx": () => import("./../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */),
  "component---src-pages-xd-plugin-jsx": () => import("./../src/pages/xd-plugin.jsx" /* webpackChunkName: "component---src-pages-xd-plugin-jsx" */)
}

